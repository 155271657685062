<template>
    <header class="hidden lg:block bg-white sticky top-0 z-1000 shadow-md h-22">
        <div
            v-if="data.menu?.menus && data.menu?.menus.length > 0"
            class="container bg-white justify-between items-center hidden lg:flex gap-2 h-full"
        >
            <nuxt-link class="w-30 lg:w-37 h-11 flex-none" to="/">
                <img src="/images/global/logo.png" alt="" class="w-full h-full object-contain" />
            </nuxt-link>
            <GlobalMegaMenu
                :dataRender="data.menu"
                :dataHasBrand="dataMenuHasBrand"
                :bannerNewCollection="data.bannerNewCollection"
            />
            <div class="flex items-center gap-3">
                <form class="flex items-center gap-3 cursor-pointer">
                    <span @click="isOpenSearch = true" class="i-ic:baseline-search text-2xl text-black"></span>
                </form>
                <div class="relative">
                    <nuxt-link :to="{ name: 'shopping_cart' }" class="inline-flex group">
                        <span
                            class="i-ic:outline-shopping-basket text-2xl text-black group-hover:text-blacks-70 group-hover:scale-110 duration-200"
                        ></span>
                        <div class="min-w-3 h-3 rounded-full bg-[#CE1010] col-flex text-white">
                            <p class="text-8px">{{ countNumberProducts }}</p>
                        </div>
                    </nuxt-link>

                    <GlobalPopupAddProduct />
                </div>
            </div>
        </div>
    </header>

    <header class="lg:hidden sticky top-0 z-100 bg-white header-mobile-shadow">
        <div class="flex items-center justify-between container h-18 bg-transparent pointer-events-auto">
            <nuxt-link class="w-30 lg:w-37 h-11 flex-none" to="/">
                <img src="/images/global/logo.png" alt="" class="w-full h-full object-contain" />
            </nuxt-link>

            <div class="flex items-center gap-6 p-2 cursor-pointer">
                <div class="relative">
                    <nuxt-link :to="{ name: 'shopping_cart' }" class="inline-flex group">
                        <span
                            class="i-ic:outline-shopping-basket text-2xl text-black group-hover:text-blacks-70 group-hover:scale-110 duration-200"
                        ></span>
                        <div class="min-w-3 h-3 rounded-full bg-[#CE1010] col-flex text-white">
                            <p class="text-8px">{{ countNumberProducts }}</p>
                        </div>
                    </nuxt-link>
                    <GlobalPopupAddProduct />
                </div>
                <div @click="isOpenNav = true">
                    <p class="i-ic:round-menu text-bw-15 w-8 h-8 text-[#344054]"></p>
                </div>
            </div>
        </div>
    </header>
    <global-popup-search :is-open-search="isOpenSearch" @hide-search="setOpenSearch" />
    <GlobalMenuMobile
        @change-open-nav="setOpenNav"
        :is-open="isOpenNav"
        :dataRender="data?.menu"
        :dataHasBrand="dataMenuHasBrand"
        :bannerNewCollection="data.bannerNewCollection"
    ></GlobalMenuMobile>
</template>

<script setup lang="ts">
const isOpenNav = ref<boolean>(false)
const isOpenSearch = ref<boolean>(false)
import { useGetShoppingCart } from '../../stores/useShoppingCart'
const { getDataMenuHeader } = useAppGlobalData()

const dataMenuHasBrand = computed(() => {
    if (data.value && data.value.menu.brands) return data.value.menu.brands
})

const { getBanner } = useBanner()
const storeShoppingCart = useGetShoppingCart()
function setOpenNav(value) {
    isOpenNav.value = value
}
function setOpenSearch(value) {
    isOpenSearch.value = value
}

const { data, pending, refresh } = useAsyncData('header', async () => {
    const [bannerNewCollection, menu] = await Promise.all([getBanner({ name: 'Menu' }), getDataMenuHeader()])
    return {
        bannerNewCollection,
        menu
    }
})

watch(
    () => useRoute().fullPath,
    () => {
        isOpenNav.value = false
    }
)
const countNumberProducts = computed(() => storeShoppingCart.checkProduct())

onMounted(async () => {
    if (getStorage('shopping_product') && getStorage('shopping_product').length > 0) {
        storeShoppingCart.numberProduct = getStorage('shopping_product')
    }
})
</script>
<style scoped>
@media (max-width: 1279px) {
    .btnDownload {
        @apply !ml-2;
    }
}
.header-mobile-shadow {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
}
</style>
